import { ListItemButton } from '@mui/material'
import React from 'react'
import { matchPath, NavLink, useLocation, useMatch } from 'react-router-dom'

type IProps = React.PropsWithChildren<{
    children: React.ReactNode
    to: string | null
    styles?: object
    disabled?: boolean
    target?: React.HTMLAttributeAnchorTarget
}>

export default function $ListItemButtonLink(props: IProps) {
    const { pathname } = useLocation()
    // const selected = !!useMatch(props.to); // при чётком совпадении пути

    const selected = !!props.to && !!matchPath(`${props.to}/*`, pathname) // при совпадении, если путь чать имени родителя

    console.log('selected', selected, 'props.to', props.to, 'pathname', pathname)

    return (
        <ListItemButton
            component={NavLink}
            to={String(props.to)}
            target={props.target}
            onClick={e => !props.to && e.preventDefault()}
            selected={selected}
            style={props.styles}
            disabled={props.disabled}
            end
            sx={{
                borderRadius: '100px',
                '&.Mui-selected': {
                    backgroundColor: 'rgba(250, 218, 235, 1)',
                    '&:hover': {
                        backgroundColor: 'rgba(250, 218, 235, 0.5)'
                    }
                },
                '&:hover': {
                    backgroundColor: 'rgba(250, 218, 235, 0.5)'
                }
            }}
        >
            {props.children}
        </ListItemButton>
    )
}
