import React from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import instance from 'api/axios'
import { Form, Formik, FormikHelpers } from 'formik'
import { useNavigate } from 'react-router'
import { enqueueSnackbar } from 'notistack'

interface ModalFormRegistrationProps {
    open: boolean
    onClose: () => void
    capturedImage: string | null
}

interface FormValues {
    lastname: string
    firstname: string
    middlename: string
    passport: string
    phone: string
}

const ModalFormRegistration: React.FC<ModalFormRegistrationProps> = ({ open, onClose, capturedImage }) => {
    const navigate = useNavigate()

    const handleClose = () => {
        onClose()
    }

    const onSubmit = async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
        try {
            const response = await instance.post('/camera/face-user', { ...values, image: capturedImage })
            console.log('Данные отправлены успешно:', response.data)
            enqueueSnackbar(response?.data?.message || '', { variant: 'success' })

            resetForm()
            setTimeout(() => navigate('/camera/category'), 2000)
        } catch (error: any) {
            console.error('Ошибка при отправке данных:', error)
            enqueueSnackbar(error?.response?.data?.message || '', { variant: 'error' })

        } finally {
            setSubmitting(false)
        }
    }

    const initialValues: FormValues = {
        lastname: '',
        firstname: '',
        middlename: '',
        passport: '',
        phone: ''
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg">
            <Grid component={Paper} height="100%" maxWidth="25rem" width="100%" padding={5}>
                <DialogTitle>
                    <Box display="flex" alignItems="center" gap={1} marginBottom={3}>
                        <IconButton aria-label="back" onClick={handleClose}>
                            <ArrowBackOutlinedIcon />
                        </IconButton>
                        <Typography variant="h5" flexGrow={1} textAlign="center">
                            Регистрация
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        validate={values => {
                            const errors: Partial<FormValues> = {}
                            if (!`${values.lastname}`.trim()) errors.lastname = 'Обязательно к заполнению'
                            if (!`${values.firstname}`.trim()) errors.firstname = 'Обязательно к заполнению'
                            if (!`${values.middlename}`.trim()) errors.middlename = 'Обязательно к заполнению'
                            if (!`${values.passport}`.trim()) errors.passport = 'Обязательно к заполнению'
                            if (`${values.passport}`.trim().length > 12) errors.passport = 'Символов не должно быть больше 12'

                            return errors
                        }}
                        onSubmit={onSubmit}
                    >
                        {({ values, errors, touched, dirty, handleChange, handleBlur, isValid }) => (
                            <Form>
                                <Box>
                                    <TextField
                                        name="lastname"
                                        fullWidth
                                        label="Фамилия"
                                        variant="outlined"
                                        value={values.lastname}
                                        error={touched.lastname && !!errors.lastname}
                                        helperText={touched.lastname && errors.lastname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <TextField
                                        name="firstname"
                                        fullWidth
                                        label="Имя"
                                        variant="outlined"
                                        value={values.firstname}
                                        error={touched.firstname && !!errors.firstname}
                                        helperText={touched.firstname && errors.firstname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <TextField
                                        name="middlename"
                                        fullWidth
                                        label="Отчество"
                                        variant="outlined"
                                        value={values.middlename}
                                        error={touched.middlename && !!errors.middlename}
                                        helperText={touched.middlename && errors.middlename}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <TextField
                                        name="passport"
                                        fullWidth
                                        label="Серия и номер паспорта"
                                        variant="outlined"
                                        value={values.passport}
                                        error={touched.passport && !!errors.passport}
                                        helperText={touched.passport && errors.passport}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <TextField
                                        name="phone"
                                        fullWidth
                                        label="Телефон"
                                        variant="outlined"
                                        value={values.phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Box>

                                <Box display="flex" justifyContent="center" mt={4}>
                                    <Button variant="contained" color="primary" type="submit" disabled={!(isValid && dirty)}>
                                        Сохранить
                                    </Button>
                                </Box>

                                <Box display="flex" justifyContent="center" mt={2}>
                                    <Button href={`/camera`} variant="text" color="primary">
                                        На главную
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Grid>
        </Dialog>
    )
}

export default ModalFormRegistration
