import React, { useEffect, useState } from 'react'
import { Box, Button, IconButton, List, Paper, Stack, TextField, Typography, ListItem, ListItemText, ListItemIcon, CircularProgress } from '@mui/material'
import { Link as RouterLink, useParams } from 'react-router-dom'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import DownloadDoneIcon from '@mui/icons-material/DownloadDone'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import EditIcon from '@mui/icons-material/Edit'
import instance from 'api/axios'
import { Form, Formik, FormikHelpers } from 'formik'
import LoadingChecks1 from './LoadingChecks1/LoadingChecks1'
import { enqueueSnackbar } from 'notistack'
import QRCode from 'react-qr-code'

export interface FormUserOrderValues {
    lastname: string
    firstname: string
    middlename: string
    passport: string
    phone?: string
    orderId?: number
    userId?: number
    mvd: boolean
    emias: boolean
    nd: boolean
    pnd: boolean
}

interface CameraUserAppointmentProps {
    // Добавьте любые дополнительные свойства, если они нужны
}

const CameraUserAppointment: React.FC<CameraUserAppointmentProps> = props => {
    const { id } = useParams<{ id: string }>()
    const [initialValues, setInitialValues] = useState<FormUserOrderValues>({
        lastname: '',
        firstname: '',
        middlename: '',
        passport: '',
        phone: '',
        mvd: false,
        emias: false,
        nd: false,
        pnd: false
    })

    const [isDispaltBlockGoverment, setIsDisplayBlockGoverment] = useState(false)
    const [isLoadingOrder, setLoadingOrder] = useState(true)
    const [failed, setFailed] = useState(false)
    const [isOrdered, setOrdered] = useState(false);

    const fetchOrder = (id: string) => {
        setLoadingOrder(true)
        instance
            .get<FormUserOrderValues>(`/camera/orders/${id}`)
            .then(res => {
                setInitialValues(res.data)
            })
            .finally(() => {
                setLoadingOrder(false)
            })
    }

    useEffect(() => {
        if (id) fetchOrder(id)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setOrdered(true)
            
        }, 5 * 2000)
    }, [])

    const onSubmit = async (values: FormUserOrderValues, { setSubmitting, resetForm }: FormikHelpers<FormUserOrderValues>) => {
        setLoadingOrder(true)
        if (values.mvd && values.emias && values.nd && values.pnd) {
            try {
                const response = await instance.post(`/camera/orders/${id}`, values)
                console.log('Данные отправлены успешно:', response.data)
                setTimeout(() => enqueueSnackbar(response?.data?.message || '', { variant: 'success' }), 5 * 2000)
                if (id) fetchOrder(id)
                resetForm()
            } catch (error: any) {
                console.error('Ошибка при отправке данных:', error)
                enqueueSnackbar(error?.response?.data?.message || '', { variant: 'error' })

            } finally {
                setSubmitting(false)
                setLoadingOrder(false)
            }
        } else {
            // Иначе отображем имитацию сервисов
        }

        setIsDisplayBlockGoverment(true)
    }

    return (
        <Box minWidth="100vw" minHeight="100vh" bgcolor="rgba(255, 248, 249, 1)" display="flex" alignItems="center" justifyContent="center">
            <Paper elevation={3} sx={{ p: 4, borderRadius: 3, maxWidth: '25rem', textAlign: 'center', overflow: 'auto', maxHeight: '100vh' }}>
                <Stack direction="column" gap={3}>
                    <Box display="flex" alignItems="center" gap={1} marginBottom={3}>
                        <IconButton aria-label="back" component={RouterLink} to="/camera/registration-method">
                            <ArrowBackOutlinedIcon />
                        </IconButton>
                        <Typography variant="h5" flexGrow={1} textAlign="center">
                            Запись
                        </Typography>
                        <IconButton aria-label="edit" component={RouterLink} to="/camera/registration-method">
                            <EditIcon />
                        </IconButton>
                    </Box>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validate={values => {
                            const errors: Partial<FormUserOrderValues> = {}
                            if (!`${values?.lastname}`.trim()) errors.lastname = 'Обязательно к заполнению'
                            if (!`${values?.firstname}`.trim()) errors.firstname = 'Обязательно к заполнению'
                            if (!`${values?.middlename}`.trim()) errors.middlename = 'Обязательно к заполнению'
                            if (!`${values?.passport}`.trim()) errors.passport = 'Обязательно к заполнению'

                            return errors
                        }}
                        onSubmit={onSubmit}
                    >
                        {({ values, errors, touched, dirty, handleChange, handleBlur, isValid }) => (
                            <Form>
                                <Box>
                                    <TextField
                                        name="lastname"
                                        fullWidth
                                        label="Фамилия"
                                        variant="outlined"
                                        value={values.lastname}
                                        error={touched.lastname && !!errors.lastname}
                                        helperText={touched.lastname && errors.lastname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        disabled
                                    />
                                    <TextField
                                        name="firstname"
                                        fullWidth
                                        label="Имя"
                                        variant="outlined"
                                        value={values.firstname}
                                        error={touched.firstname && !!errors.firstname}
                                        helperText={touched.firstname && errors.firstname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        disabled
                                    />
                                    <TextField
                                        name="middlename"
                                        fullWidth
                                        label="Отчество"
                                        variant="outlined"
                                        value={values.middlename}
                                        error={touched.middlename && !!errors.middlename}
                                        helperText={touched.middlename && errors.middlename}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        disabled
                                    />
                                    <TextField
                                        name="passport"
                                        fullWidth
                                        label="Серия и номер паспорта"
                                        variant="outlined"
                                        value={values.passport}
                                        error={touched.passport && !!errors.passport}
                                        helperText={touched.passport && errors.passport}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        disabled
                                    />
                                    <TextField
                                        name="phone"
                                        fullWidth
                                        label="Телефон"
                                        variant="outlined"
                                        value={values.phone}
                                        error={touched.phone && !!errors.phone}
                                        helperText={touched.phone && errors.phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        disabled
                                    />
                                </Box>

                                {initialValues.userId ? (
                                    <>
                                        {isOrdered ? (
                                            <Box textAlign="left" p={2} bgcolor="rgba(247, 251, 242, 1)" borderRadius={3} boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)" mt={2}>
                                                <Typography variant="subtitle1" fontWeight={600}>
                                                    Вы записаны
                                                </Typography>
                                                <QRCode
                                                    size={12}
                                                    style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                                                    value={`${process.env.PUBLIC_URL}/camera/appointment/${initialValues.orderId}`}
                                                    viewBox={`0 0 256 256`}
                                                />
                                            </Box>
                                        ) : (
                                            <Stack alignItems={'center'} justifyContent={'center'} gap={2}>
                                                <CircularProgress />
                                                <Typography>Проверяем запись...</Typography>
                                            </Stack>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {!isLoadingOrder && (
                                            <Box display="flex" justifyContent="center" mt={4}>
                                                <Button variant="contained" color="primary" type="submit" disabled={!!initialValues.userId || isLoadingOrder || failed}>
                                                    Записаться
                                                </Button>
                                            </Box>
                                        )}
                                    </>
                                )}

                                {/* == Запись не состоялась == */}
                                {false && (
                                    <Box textAlign="left" p={2} bgcolor="rgba(228, 105, 98, 0.2)" borderRadius={3} boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)" mt={2}>
                                        <Typography variant="subtitle1" fontWeight={600}>
                                            Запись не состоялась
                                        </Typography>
                                        <List>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <DownloadDoneIcon color="success" />
                                                </ListItemIcon>
                                                <ListItemText primary="Проверка по базе МВД" />
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <DoNotDisturbIcon color="error" />
                                                </ListItemIcon>
                                                <ListItemText primary="Элемент 2" />
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <DoNotDisturbIcon color="error" />
                                                </ListItemIcon>
                                                <ListItemText primary="Элемент 3" />
                                            </ListItem>
                                        </List>
                                    </Box>
                                )}
                                {/* == Запись не состоялась == */}

                                {isDispaltBlockGoverment && <LoadingChecks1 user={initialValues} failed={failed} setFailed={setFailed} />}

                                <Box display="flex" justifyContent="center" mt={2}>
                                    <Button href={`/camera`} variant="text" color="primary">
                                        На главную
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Stack>
            </Paper>
        </Box>
    )
}

export default CameraUserAppointment
