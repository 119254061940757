import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import * as faceapi from 'face-api.js'
import instance from 'api/axios'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { enqueueSnackbar } from 'notistack'
interface User {
    id: number
    fullname: string
    urlPhoto: string
}

const FaceScanner: React.FC = () => {
    const videoRef = useRef<HTMLVideoElement | null>(null)
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    const [initialized, setInitialized] = useState(false)
    const [loading, setLoading] = useState(true)
    const [labeledDescriptors, setLabeledDescriptors] = useState<faceapi.LabeledFaceDescriptors[]>([])
    const [users, setUsers] = useState<User[]>([])
    const [labels, setLabels] = useState<string[]>([])
    const navigate = useNavigate()

    useEffect(() => {
        // Загружаем список пользователей с сервера
        instance.get('/camera/users').then(res => {
            setUsers(res.data)
        }).catch((error) => {
            enqueueSnackbar(error?.response?.data?.message || '', { variant: 'error' })
        })
    }, [])

    useEffect(() => {
        // Загружаем модели face-api.js, если есть пользователи
        const loadModels = async () => {
            const MODEL_URL = '/models'
            Promise.all([
                faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL),
                faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
                faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
            ]).then(async () => {
                setInitialized(true)
                await loadLabeledImages()
            })
            // await faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL)
            // await faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL)
            // await faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL)
            
        }
        if (users.length > 0) loadModels()
    }, [users.length])

    const startVideo = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true })
            if (videoRef.current) videoRef.current.srcObject = stream
        } catch (error) {
            enqueueSnackbar('Ошибка доступа к камере!', { variant: 'error' })
        }
    }

    const loadLabeledImages = async () => {
        const descriptors = await Promise.all(
            users.map(async item => {
                const img = await faceapi.fetchImage(item.urlPhoto) // http://localhost:9090/public/uploads/camera/users/4.jpg
                const detections = await faceapi.detectSingleFace(img).withFaceLandmarks().withFaceDescriptor()
                if (!detections) {
                    enqueueSnackbar(`Лицо для ${item.fullname} не найдено` , { variant: 'error' })

                    throw new Error(`Лицо для ${item.fullname} не найдено`)
                }
                return new faceapi.LabeledFaceDescriptors(item.fullname, [detections.descriptor])
            })
        )
        setLabeledDescriptors(descriptors)
        setLoading(false)
    }

    useEffect(() => {
        if (!loading) startVideo()
    }, [loading])

    const handleVideoOnPlay = () => {
        if (initialized && labeledDescriptors.length > 0) {
            const canvas = canvasRef.current
            const displaySize = { width: videoRef.current?.videoWidth || 640, height: videoRef.current?.videoHeight || 480 }
            if (canvas) faceapi.matchDimensions(canvas, displaySize)

            const detectFaces = async () => {
                if (videoRef.current && canvas) {
                    const detections = await faceapi.detectAllFaces(videoRef.current).withFaceLandmarks().withFaceDescriptors()
                    const resizedDetections = faceapi.resizeResults(detections, displaySize)
                    const context = canvas.getContext('2d')
                    if (context) context.clearRect(0, 0, canvas.width, canvas.height)

                    const faceMatcher = new faceapi.FaceMatcher(labeledDescriptors, 0.6)
                    const results = resizedDetections.map(d => faceMatcher.findBestMatch(d.descriptor))

                    results.forEach((result, i) => {
                        const box = resizedDetections[i].detection.box
                        const label = result.toString()
                        const drawBox = new faceapi.draw.DrawBox(box, { label })
                        drawBox.draw(canvas)
                    })
                    setLabels(results.map(result => result.label?.toString()))

                    requestAnimationFrame(detectFaces)
                }
            }

            detectFaces()
        }
    }

    const stopVideo = () => {
        if (videoRef.current && videoRef.current.srcObject) {
            const stream = videoRef.current.srcObject as MediaStream
            const tracks = stream.getTracks()
            tracks.forEach(track => track.stop())
            videoRef.current.srcObject = null
        }
    }

    useEffect(() => {
        if (videoRef.current && !loading) {
            videoRef.current.addEventListener('play', handleVideoOnPlay)
        }
        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('play', handleVideoOnPlay)
            }
        }
    }, [initialized, labeledDescriptors, loading])

    return (
        <Stack alignItems="center" spacing={2}>
            {loading ? (
                <Stack alignItems="center" spacing={2}>
                    <CircularProgress />
                    <Typography variant="h6">Загрузка параметров...</Typography>
                </Stack>
            ) : (
                <Stack position="relative" display="flex" mb={4} alignItems={'start'} justifyItems={'start'}>
                    <video ref={videoRef} autoPlay muted style={{ borderRadius: '10px', width: '100%' }} />
                    <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, right: 0, borderRadius: '10px', width: '100%' }} />
                </Stack>
            )}
            <Typography variant="h5" gutterBottom>
                Распознавание лиц в реальном времени
            </Typography>
            <Stack direction="row" justifyContent="center" spacing={2} mt={2}>
                <Button
                    disabled={!users.find(user => labels.includes(user.fullname))}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        const user = users.find(user => labels.includes(user.fullname))
                        if (user) navigate(`/camera/appointment/${user.id}`, { replace: true })
                    }}
                    sx={{ borderRadius: 2 }}
                >
                    Войти
                </Button>
            </Stack>
            <Stack>
                <Button variant="contained" color="primary" onClick={stopVideo} sx={{ display: 'none' }}>
                    Остановить камеру
                </Button>
            </Stack>
        </Stack>
    )
}

export default FaceScanner
