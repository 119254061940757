import React, { useState, useEffect, useRef } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import * as faceapi from 'face-api.js'
import ModalFaceCapturePreview from './ModalFaceCapturePreview/ModalFaceCapturePreview'

type TProps = React.PropsWithChildren<{}>

const CameraCapture: React.FC<TProps> = () => {
    const videoRef = useRef<HTMLVideoElement | null>(null)
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    const [photoTaken, setPhotoTaken] = useState(false)
    const [faceDetected, setFaceDetected] = useState(false)
    const [faceBox, setFaceBox] = useState<faceapi.Box | null>(null) // Состояние для отслеживания рамки лица
    const [capturedImage, setCapturedImage] = useState<string | null>(null) // Хранит захваченное изображение
    const [openFaceCapturePreview, setOpenFaceCapturePreview] = useState(false)

    const handleOpenFaceCapturePreview = () => setOpenFaceCapturePreview(true)
    const handleCloseFaceCapturePreview = () => setOpenFaceCapturePreview(false)

    // Инициализация камеры
    useEffect(() => {
        async function startCamera() {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true })
                if (videoRef.current) {
                    videoRef.current.srcObject = stream
                    videoRef.current.onloadedmetadata = () => {
                        if (canvasRef.current && videoRef.current) {
                            canvasRef.current.width = videoRef.current.videoWidth
                            canvasRef.current.height = videoRef.current.videoHeight
                            updateFaceBox()
                        }
                    }
                }
            } catch (error) {
                console.error('Ошибка доступа к камере:', error)
            }
        }

        async function loadModels() {
            await faceapi.nets.tinyFaceDetector.loadFromUri('/models')
            await faceapi.nets.faceLandmark68Net.loadFromUri('/models')
            await faceapi.nets.faceRecognitionNet.loadFromUri('/models')
        }

        startCamera()
        loadModels()
    }, [])

    // Функция для обновления рамки лица
    const updateFaceBox = async () => {
        if (videoRef.current) {
            const detections = await faceapi.detectSingleFace(videoRef.current, new faceapi.TinyFaceDetectorOptions())

            if (detections) {
                setFaceBox(detections.box)
                setFaceDetected(true)
            } else {
                setFaceDetected(false)
                setFaceBox(null)
            }
        }

        requestAnimationFrame(updateFaceBox) // Запускаем обновление рамки каждый кадр
    }

    // Рисуем рамку
    useEffect(() => {
        const canvas = canvasRef.current
        if (canvas && faceBox) {
            const context = canvas.getContext('2d')
            if (context) {
                context.clearRect(0, 0, canvas.width, canvas.height)

                if (faceDetected && faceBox) {
                    context.strokeStyle = 'rgba(235, 221, 255, 1)'
                    context.lineWidth = 2
                    context.strokeRect(faceBox.x, faceBox.y, faceBox.width, faceBox.height)
                }
            }
        }
    }, [faceBox, faceDetected])

    // Функция для фотографирования лица
    const handleCapture = () => {
        if (faceBox && canvasRef.current && videoRef.current) {
            const canvas = canvasRef.current
            const video = videoRef.current
            const context = canvas.getContext('2d')

            if (context) {
                canvas.width = video.videoWidth
                canvas.height = video.videoHeight
                context.drawImage(video, 0, 0, canvas.width, canvas.height)

                const imageDataUrl = canvas.toDataURL('image/jpeg', 0.6)
                setCapturedImage(imageDataUrl)
                setPhotoTaken(true)
            }
        }
    }

    useEffect(() => {
        if (photoTaken && capturedImage) {
            handleOpenFaceCapturePreview() // Открываем модальное окно с изображением
        }
    }, [photoTaken, capturedImage])

    return (
        <Stack>
            <React.Fragment>
                <Stack position="relative" display="flex" mb={4} alignItems={'start'} justifyItems={'start'}>
                    <video ref={videoRef} autoPlay muted style={{ borderRadius: '10px', width: '100%' }} />
                    <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, right: 0, borderRadius: '10px', width: '100%' }} />
                </Stack>
            </React.Fragment>

            <Stack direction="row" justifyContent="center" spacing={2} mt={2}>
                <Button variant="contained" color="primary" disabled={!faceDetected} onClick={handleCapture}>
                    {photoTaken ? 'Сделать еще один снимок' : 'Сделать снимок'}
                </Button>
                <ModalFaceCapturePreview capturedImage={capturedImage} open={openFaceCapturePreview} onClose={handleCloseFaceCapturePreview} />
            </Stack>
        </Stack>
    )
}

export default CameraCapture
