import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { useQuery } from 'react-query'

type IStore = {
    isAuth: boolean
    token: string
    userId: number | null
    setAuthAction(isAuth: boolean): void
    setTokenAction(token: string): void
    setUserIdAction(userId: IStore['userId']): void
}

export const useAuthStore = create<IStore>()(
    persist(
        (set, get, store) => ({
            isAuth: false,
            token: '',
            userId: null,
            setAuthAction(isAuth) {
                set({ isAuth })
            },
            setTokenAction(token) {
                set({ token })
            },
            setUserIdAction(userId) {
                set({ userId })
            }
        }),
        {
            name: 'auth',
            skipHydration: false,
            storage: createJSONStorage(() => process.env.REACT_APP_SESSION_STORAGE === "true" ? sessionStorage : localStorage)
        }
    )
)
