import React, { Suspense } from 'react'
import './App.css'
import { BrowserRouter, createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider, Routes, useNavigate, useParams } from 'react-router-dom'
import { useAuthStore } from 'store/auth'
import VerifyEmailPage from 'pages/VerifyEmailPage/VerifyEmailPage'
import LayoutMain from 'components/Layouts/LayoutApp/LayoutMain'
import LayoutAuth from 'components/Layouts/LayoutAuth/LayoutAuth'
import { LinearProgress } from '@mui/material'
import { useQuery } from 'react-query'
import { getWorkspaceByNameApi } from 'api/workspace/workspace'
import { useWorkspaceStore } from 'store/workspace'
import $ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute'
import { ROLES } from 'store/user-profile'
import LayoutWorkspaceSetting from './components/Layouts/LayoutWorkspaceSetting/LayoutWorkspaceSetting'
import CameraCategorySelection from './pages/Camera/CameraCategorySelection/CameraCategorySelection'
import CameraFaceRegistration from './pages/Camera/CameraFaceRegistration/CameraFaceRegistration'
import CameraRegistrationMethod from './pages/Camera/CameraRegistrationMethod/CameraRegistrationMethod'
import CameraFaceSignIn from 'pages/Camera/CameraFaceSignIn/CameraFaceSignIn'
import CameraSignIn from 'pages/Camera/CameraSignIn/CameraSignIn'
import CameraUserAppointment from './pages/Camera/CameraUserAppointment/CameraUserAppointment'
import CameraPage from 'pages/Camera/CameraPage/CameraPage'

const RootPage = React.lazy(() => import('./pages/RootPage/RootPage'))
const FormPage = React.lazy(() => import('./pages/FormPage/FormPage'))

const LandingPage = React.lazy(() => import('./pages/LandingPage/LandingPage'))
const SignInPage = React.lazy(() => import('./pages/SignInPage/SignInPage'))
const SignUpPage = React.lazy(() => import('./pages/SignUpPage/SignUpPage'))
const RecoveryAccountPage = React.lazy(() => import('./pages/RecoveryAccountPage/RecoveryAccountPage'))
const ResetPasswordPage = React.lazy(() => import('./pages/ResetPasswordPage/ResetPasswordPage'))
const HomePage = React.lazy(() => import('./pages/HomePage/HomePage'))
const ChatTextPage = React.lazy(() => import('./pages/ChatTextPage/ChatTextPage'))
const MeetingPage = React.lazy(() => import('./pages/MeetingPage/MeetingPage'))
const WorkspaceSettingsPage = React.lazy(() => import('./pages/WorkspaceSettingsPage/WorkspaceSettingsPage'))
const WorkspaceAdministrationUsersPage = React.lazy(() => import('./pages/WorkspaceAdministrationUsersPage/WorkspaceAdministrationUsersPage'))
const WorkspaceShopPage = React.lazy(() => import('./pages/WorkspaceShopPage/WorkspaceShopPage'))
const DiskPage = React.lazy(() => import('./pages/DiskPage/DiskPage'))
const CalendarPage = React.lazy(() => import('./pages/CalendarPage/CalendarPage'))
const WorkspaceNotFoundPage = React.lazy(() => import('./pages/WorkspaceNotFoundPage/WorkspaceNotFoundPage'))
const MeetingCallPage = React.lazy(() => import('./pages/MeetingCallPage/MeetingCallPage'))
const MeetingMapPage = React.lazy(() => import('./pages/MeetingMapPage/MeetingMapPage'))
const ControlScreensPage = React.lazy(() => import('./pages/ControlScreensPage/ControlScreensPage'))
const ZoomPage = React.lazy(() => import('./pages/ZoomPage/ZoomPage'))
const ZegoCloudPage = React.lazy(() => import('./pages/ZegoCloudPage/ZegoCloudPage'))
const LiveKitHomePage = React.lazy(() => import('./pages/LiveKitHomePage/LiveKitHomePage'))
const LiveKitMeetingPage = React.lazy(() => import('./pages/LiveKitMeetingPage/LiveKitMeetingPage'))
const JitSiHomePage = React.lazy(() => import('./pages/JitSiHomePage/JitSiHomePage'))
const JitSiPage = React.lazy(() => import('./pages/JitSiPage/JitSiPage'))
// const CameraPage = React.lazy(() => import('./pages/Camera/CameraPage/CameraPage'))
const ProfileSettingsPage = React.lazy(() => import('pages/ProfileSettingsPage/ProfileSettingsPage'))
const FaceSettingsPanelPage = React.lazy(() => import('pages/FaceSettingsPanelPage/FaceSettingsPanelPage'))

const SuspenseLayout = React.memo(() => {
    const naviagte = useNavigate()
    const { linkWorkspace } = useParams()
    const { setWorkspace } = useWorkspaceStore()

    const { data, isLoading, error } = useQuery('workspace', () => getWorkspaceByNameApi(linkWorkspace!), {
        onSuccess(data) {
            setWorkspace(data)
        },
        onError(err) {
            naviagte(`/${linkWorkspace}/workspace-not-found`)
        }
    })

    return (
        <Suspense fallback={<LinearProgress color="primary" />}>
            <Outlet />
        </Suspense>
    )
})

function App() {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route path="/camera" element={<CameraPage />} />
                <Route path="/camera/category" element={<CameraCategorySelection />} /> {/* Выбор категории */}
                <Route path="/camera/registration-method" element={<CameraRegistrationMethod />} /> {/* Страница с выбором метода регистрации*/}
                <Route path="/camera/face-registration" element={<CameraFaceRegistration />} /> {/* Регистрация по биометрии */}
                <Route path="/camera/face-signin" element={<CameraFaceSignIn />} /> {/* Вход по биометрии */}
                <Route path="/camera/signin" element={<CameraSignIn />} /> {/* Вход без биометрии */}
                <Route path="/camera/appointment/:id" element={<CameraUserAppointment />} /> {/* страница с созданием талонов */}
                {/**/}
                <Route path="/:linkWorkspace" element={<SuspenseLayout />}>
                    {/* Слой авторизации */}
                    <Route element={<LayoutAuth />}>
                        <Route path="sign-in" element={<SignInPage />} />
                        <Route path="recovery-account">
                            <Route path=":login" element={<RecoveryAccountPage />} />
                            <Route index element={<RecoveryAccountPage />} />
                        </Route>
                        <Route path="reset-password">
                            <Route path=":accept" element={<ResetPasswordPage />} />
                            <Route index element={<ResetPasswordPage />} />
                        </Route>
                        <Route path="sign-up">
                            <Route path=":accept" element={<SignUpPage />} />
                            <Route index element={<SignUpPage />} />
                        </Route>
                        <Route path="verify-email">
                            <Route path=":email/:verifyEmailCode" element={<VerifyEmailPage />} />
                            <Route path=":email" element={<VerifyEmailPage />} />
                            <Route index element={<VerifyEmailPage />} />
                        </Route>
                    </Route>
                    {/* Слой авторизации */}

                    {/* <Route path="" index element={authStore.isAuth ? <Navigate to={`sign-in`} />} /> */}

                    {/* Слой приложения */}
                    <Route element={<LayoutMain />}>
                        <Route path="home" element={<HomePage />} />
                        <Route path="chat-text">
                            <Route path=":chatId/:chatType" element={<ChatTextPage />} />
                            <Route index element={<ChatTextPage />} />
                        </Route>
                        <Route path="meeting">
                            <Route path="call/:channel" element={<MeetingCallPage />} />
                            <Route path="map/:channel" element={<MeetingMapPage />} />
                            <Route index element={<MeetingPage />} />
                        </Route>
                        <Route
                            path="workspace-settings"
                            element={
                                <$ProtectedRoute roles={[ROLES.OWNER, ROLES.ADMIN]}>
                                    <LayoutWorkspaceSetting />
                                </$ProtectedRoute>
                            }
                        >
                            <Route
                                path="administration-users"
                                element={
                                    <$ProtectedRoute roles={[ROLES.OWNER, ROLES.ADMIN]}>
                                        <WorkspaceAdministrationUsersPage />
                                    </$ProtectedRoute>
                                }
                            />

                            <Route
                                path="shop"
                                element={
                                    <$ProtectedRoute roles={[ROLES.OWNER, ROLES.ADMIN]}>
                                        <WorkspaceShopPage />
                                    </$ProtectedRoute>
                                }
                            />
                            <Route
                                index
                                element={
                                    <$ProtectedRoute roles={[ROLES.OWNER, ROLES.ADMIN]}>
                                        <WorkspaceSettingsPage />
                                    </$ProtectedRoute>
                                }
                            />
                        </Route>
                        <Route path="disk" element={<DiskPage />} />
                        <Route path="chat-calendar" element={<CalendarPage />} />
                        <Route path="admin-donor-service" element={<FaceSettingsPanelPage />} />
                        <Route path="profile-settings" element={<ProfileSettingsPage />} />

                        <Route path="control-screens" element={<ControlScreensPage />} />

                        <Route path="zegocloud" element={<ZegoCloudPage />} />

                        <Route path="livekit">
                            <Route path=":roomId" element={<LiveKitMeetingPage />} />
                            <Route index element={<LiveKitHomePage />} />
                        </Route>

                        <Route path="jitsi">
                            <Route path=":roomId" element={<JitSiPage />} />
                            <Route index element={<JitSiHomePage />} />
                        </Route>
                    </Route>

                    {/* Слой лендинга */}
                    <Route path="" element={<LandingPage />} />
                    {/* Слой лендинга */}

                    {/* Пространство не существует */}
                    <Route path="workspace-not-found" element={<WorkspaceNotFoundPage />} />
                    {/* Пространство не существует */}

                    {/* <Route path="/" index element={<Navigate to={authStore.isAuth ? "/sign-in" : "/"} />} /> */}
                </Route>
                <Route path="/" element={<RootPage />} />
                <Route path="/form" element={<FormPage />} />
            </>
        )
    )

    return <RouterProvider router={router} />
}

export default App
