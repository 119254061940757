import React from 'react'
import { Box, Button, IconButton, Paper, Stack, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import { Form, Formik, FormikHelpers } from 'formik'
import instance from 'api/axios'
import { enqueueSnackbar } from 'notistack'

interface FormValues {
    lastname: string
    firstname: string
    // middlename: string;
    passport: string
    // phone?: string;
}

interface CameraSignInProps {
    // Any additional props can be specified here if needed
}

const CameraSignIn: React.FC<CameraSignInProps> = props => {
    const navigate = useNavigate()

    const onSubmit = async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
        try {
            const response = await instance.post('/camera/login', values)
            console.log('Данные отправлены успешно:', response.data)
            resetForm()
            navigate(`/camera/appointment/${response.data.id}`, { replace: true })
        } catch (error: any) {
            enqueueSnackbar(error?.response?.data?.message || '', { variant: 'error',  })

            console.error('Ошибка при отправке данных:', error?.response?.data?.message)
        } finally {
            setSubmitting(false)
        }
    }

    const initialValues: FormValues = {
        lastname: '',
        firstname: '',
        // middlename: '',
        passport: ''
        // phone: '',
    }

    return (
        <Box minWidth="100vw" minHeight="100vh" bgcolor="rgba(255, 248, 249, 1)" display="flex" alignItems="center" justifyContent="center">
            <Paper elevation={3} sx={{ p: 4, borderRadius: 3, maxWidth: '25rem', textAlign: 'center' }}>
                <Stack direction="column" gap={3}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                        <IconButton onClick={() => navigate(-1)}>
                            <ArrowBackOutlinedIcon />
                        </IconButton>
                        <Typography variant="h5" flexGrow={1} textAlign="center">
                            Вход
                        </Typography>
                        <IconButton sx={{ visibility: 'hidden' }}>
                            <ArrowBackOutlinedIcon />
                        </IconButton>
                    </Box>
                    <Formik
                        initialValues={initialValues}
                        validate={values => {
                            const errors: Partial<FormValues> = {}
                            if (!`${values.lastname}`.trim()) errors.lastname = 'Обязательно к заполнению'
                            if (!`${values.firstname}`.trim()) errors.firstname = 'Обязательно к заполнению'
                            // if (!values.middlename.trim()) errors.middlename = 'Обязательно к заполнению';
                            if (!`${values.passport}`.trim()) errors.passport = 'Обязательно к заполнению'

                            return errors
                        }}
                        onSubmit={onSubmit}
                    >
                        {({ values, errors, touched, dirty, handleChange, handleBlur, isValid }) => (
                            <Form>
                                <Box>
                                    <TextField
                                        name="lastname"
                                        fullWidth
                                        label="Фамилия"
                                        variant="outlined"
                                        value={values.lastname}
                                        error={touched.lastname && !!errors.lastname}
                                        helperText={touched.lastname && errors.lastname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <TextField
                                        name="firstname"
                                        fullWidth
                                        label="Имя"
                                        variant="outlined"
                                        value={values.firstname}
                                        error={touched.firstname && !!errors.firstname}
                                        helperText={touched.firstname && errors.firstname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    {/* <TextField
                                        name="middlename"
                                        fullWidth
                                        label="Отчество"
                                        variant="outlined"
                                        value={values.middlename}
                                        error={touched.middlename && !!errors.middlename}
                                        helperText={touched.middlename && errors.middlename}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                    /> */}
                                    <TextField
                                        name="passport"
                                        fullWidth
                                        label="Серия и номер паспорта"
                                        variant="outlined"
                                        value={values.passport}
                                        error={touched.passport && !!errors.passport}
                                        helperText={touched.passport && errors.passport}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    {/* <TextField
                                        name="phone"
                                        fullWidth
                                        label="Телефон"
                                        variant="outlined"
                                        value={values.phone}
                                        error={touched.phone && !!errors.phone}
                                        helperText={touched.phone && errors.phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                    /> */}
                                </Box>

                                <Box display="flex" justifyContent="center" mt={4}>
                                    <Button variant="contained" color="primary" type="submit" disabled={!(isValid && dirty)}>
                                        Продолжить
                                    </Button>
                                </Box>

                                <Box display="flex" justifyContent="center" mt={2}>
                                    <Button href={`/camera`} variant="text" color="primary">
                                        На главную
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Stack>
            </Paper>
        </Box>
    )
}

export default CameraSignIn
