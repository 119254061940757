import React from 'react'
import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material'
import WaterDropIcon from '@mui/icons-material/WaterDrop'
import RepeatIcon from '@mui/icons-material/Repeat'
import PublicIcon from '@mui/icons-material/Public'
import logo from '../CameraPage/logoSK.svg'
import { Link } from 'react-router-dom'

const CameraCategorySelection: React.FC = () => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh" padding={4} sx={{ backgroundColor: '#f5f5f5' }}>
            <img src={logo} alt="Логотип Службы Крови" style={{ marginBottom: '24px', width: '250px' }} />
            <Typography variant="h5" color="textPrimary" fontWeight="bold" sx={{ marginBottom: '32px' }}>
                Выберите категорию
            </Typography>
            <Box display="flex" flexDirection="column" gap={3} sx={{ marginBottom: '40px' }}>
                <Card
                    sx={{ width: 300, height: 150, textAlign: 'center', boxShadow: 3, borderRadius: '12px', textDecoration: 'none' }}
                    component={Link}
                    to="/camera/registration-method"
                >
                    <CardActionArea sx={{ height: '100%' }}>
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                            <WaterDropIcon sx={{ fontSize: 60, color: '#007B89', marginBottom: 2 }} />
                            <Typography variant="body1" color="#007B89" fontWeight="bold" sx={{ fontSize: '18px' }}>
                                Первичная донация
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>

                <Card
                    sx={{ width: 300, height: 150, textAlign: 'center', boxShadow: 3, borderRadius: '12px', textDecoration: 'none' }}
                    component={Link}
                    to="/camera/registration-method"
                >
                    <CardActionArea sx={{ height: '100%' }}>
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                            <RepeatIcon sx={{ fontSize: 60, color: '#007B89', marginBottom: 2 }} />
                            <Typography variant="body1" color="#007B89" fontWeight="bold" sx={{ fontSize: '18px' }}>
                                Повторная донация
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>

                <Card
                    sx={{ width: 300, height: 150, textAlign: 'center', boxShadow: 3, borderRadius: '12px', textDecoration: 'none' }}
                    component={Link}
                    to="/camera/registration-method"
                >
                    <CardActionArea sx={{ height: '100%' }}>
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                            <PublicIcon sx={{ fontSize: 60, color: '#007B89', marginBottom: 2 }} />
                            <Typography variant="body1" color="#007B89" fontWeight="bold" sx={{ fontSize: '18px' }}>
                                Иностранный гражданин
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Box>
            <Typography variant="body2" color="#007B89" sx={{ cursor: 'pointer', fontSize: '18px', textDecoration: 'none' }} component={Link} to="/camera">
                На главную
            </Typography>
        </Box>
    )
}

export default CameraCategorySelection
