import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, CircularProgress } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import { useEffect, useState } from 'react'
import { FormUserOrderValues } from '../CameraUserAppointment'

type TProps = {
    failed: boolean
    setFailed: React.Dispatch<React.SetStateAction<boolean>>
    user: FormUserOrderValues
}

interface Check {
    key: Extract<keyof FormUserOrderValues, 'mvd' | 'emias' | 'nd' | 'pnd'>
    label: string
    completed: boolean
    failed: boolean
}

const LoadingChecks1: React.FC<TProps> = (props) => {
    const [checks, setChecks] = useState<Check[]>([
        { label: 'Проверка по базе МВД', completed: false, failed: false, key: 'mvd' },
        { label: 'Проверка запретов ЕМИАС', completed: false, failed: false, key: 'emias' },
        { label: 'Проверка НД', completed: false, failed: false, key: 'nd' },
        { label: 'Проверка ПНД', completed: false, failed: false, key: 'pnd' }
    ])
    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
        if (currentIndex < checks.length) {
            const timer = setTimeout(() => {
                setChecks(prevChecks => {
                    const newChecks = [...prevChecks]

                    
                    if (props.user[newChecks[currentIndex].key]) {
                        newChecks[currentIndex].completed = true
                        newChecks[currentIndex].failed = false
                    } else {
                        newChecks[currentIndex].completed = false
                        newChecks[currentIndex].failed = true
                        props.setFailed(true)
                    }
                
                    
                    return newChecks
                })
                setCurrentIndex(prevIndex => prevIndex + 1)
            }, 2000)
            return () => clearTimeout(timer)
        }
    }, [currentIndex, props.failed, checks.length])

    return (
        <Box
            mt={2}
            p={2}
            sx={{
                backgroundColor: props.failed ? 'rgba(228, 105, 98, 0.2)' : 'rgba(247, 251, 242, 1)',
                borderRadius: '10px',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
        >
            <Typography variant="subtitle1" fontWeight={600} mb={1} color={props.failed ? 'error.main' : 'textPrimary'}>
                {props.failed ? 'Запись не состоялась' : 'Осуществляем запись...'}
            </Typography>
            <List>
                {checks.map((check, index) => (
                    <ListItem key={index} disableGutters>
                        <ListItemIcon>
                            {check.completed ? <CheckCircleIcon color="success" /> : check.failed ? <DoNotDisturbIcon color="error" /> : <CircularProgress size={20} />}
                        </ListItemIcon>
                        <ListItemText primary={check.label} />
                    </ListItem>
                ))}
            </List>
        </Box>
    )
}

export default LoadingChecks1
