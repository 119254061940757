import React, { useEffect, useState } from 'react'
import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material'
import VideoCallIcon from '@mui/icons-material/VideoCall'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import logo from './logoSK.svg'
import logoGos from './logoGos.svg' // Импортируем SVG иконку для Госуслуг
import { Link } from 'react-router-dom'
import instance from 'api/axios'
import { enqueueSnackbar } from 'notistack'

const CameraPage: React.FC = () => {
    const [link, setLink] = useState('')

    useEffect(() => {
        instance
            .get('/camera/service-link')
            .then(res => {
                console.log('res.data.data.link', res.data.data)
                setLink(res.data.data)
            })
            .catch((error: any) => {
                enqueueSnackbar(error?.response?.data?.message || '', { variant: 'error' })
            })
    }, [])
    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh" padding={4} sx={{ backgroundColor: '#f5f5f5' }}>
            <img src={logo} alt="Логотип Службы Крови" style={{ marginBottom: '24px', width: '250px' }} />
            <Box display="flex" flexDirection="column" gap={3} sx={{ marginBottom: '40px' }}>
                {/* Карточка для "Госуслуги" с увеличенным логотипом */}
                <Card
                    sx={{ width: 300, height: 150, textAlign: 'center', boxShadow: 3, borderRadius: '12px', textDecoration: 'none' }}
                    component={Link}
                    to="https://esia.gosuslugi.ru/login/"
                >
                    <CardActionArea sx={{ height: '100%' }}>
                        <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                            <img src={logoGos} alt="Госуслуги" style={{ width: '90%', height: '90%' }} />
                        </CardContent>
                    </CardActionArea>
                </Card>

                <Card sx={{ width: 300, height: 150, textAlign: 'center', boxShadow: 3, borderRadius: '12px', textDecoration: 'none' }} component={Link} to={link}>
                    <CardActionArea sx={{ height: '100%' }}>
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                            <VideoCallIcon sx={{ fontSize: 60, color: '#007B89', marginBottom: 2 }} />
                            <Typography variant="body1" color="#007B89" fontWeight="bold" sx={{ fontSize: '18px' }}>
                                Видеоконсультация врача
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>

                <Card sx={{ width: 300, height: 150, textAlign: 'center', boxShadow: 3, borderRadius: '12px', textDecoration: 'none' }} component={Link} to="/camera/category">
                    <CardActionArea sx={{ height: '100%' }}>
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                            <CalendarTodayIcon sx={{ fontSize: 60, color: '#007B89', marginBottom: 2 }} />
                            <Typography variant="body1" color="#007B89" fontWeight="bold" sx={{ fontSize: '18px' }}>
                                Запись к врачу
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Box>
        </Box>
    )
}

export default CameraPage
