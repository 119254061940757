import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { ROLES } from './user-profile'

export type IItemWorkspace = {
    id: number
    userId: number
    modeConnection: MODE_CONNECTION_OF_WORKSPACE
    linkWorkspace: string | null
    urlImage: string | null
    title: string | null
    description: string | null
    workspacePolicy: string | null
    userRoleId: ROLES | undefined | null
}

export enum MODE_CONNECTION_OF_WORKSPACE {
    FREE = 'free',
    INVITE = 'invite'
}

type IStore = {
    openMenuWorkspace: boolean
    workspace: IItemWorkspace | null
    setWorkspace(workspace: IStore['workspace']): void
    setOpenMenuWorkspace(openMenuWorkspace: IStore['openMenuWorkspace']): void
}

export const useWorkspaceStore = create<IStore>()(
    persist(
        (set, get, store) => ({
            workspace: null,
            openMenuWorkspace: true,
            setWorkspace(workspace) {
                set({ workspace })
            },
            setOpenMenuWorkspace(openMenuWorkspace) {
                set({ openMenuWorkspace })
            },
        }),
        {
            name: 'workspace',
            skipHydration: false
        }
    )
)
