import { createTheme, ThemeProvider, alpha } from '@mui/material/styles'
import { red, grey } from '@mui/material/colors'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import 'dayjs/locale/ru'

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#844C72'
        },
        secondary: {
            main: '#705766'
        },
        error: {
            main: '#BA1A1A'
        },
        success: {
            main: 'rgba(39, 106, 73, 1)'
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                size: 'small',
                variant: 'filled',
                fullWidth: true
            }
        },
        MuiButton: {
            defaultProps: {
                size: 'small',
                variant: 'text',
                fullWidth: false
            },
            styleOverrides: {
                root: {
                    borderRadius: '100px',
                    padding: '10px 24px 8px',
                    textTransform: 'none',
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    letterSpacing: '0.1px'
                }
            },
            variants: [
                {
                    props: { color: 'primary', variant: 'contained' },
                    style: ({ theme }) => ({
                        boxShadow: 'none',
                        '&:hover': {
                            backgroundColor: alpha(theme.palette.primary.main, 1),
                            boxShadow: `0 0 0 0.2rem ${alpha(theme.palette.primary.main, 0.5)}`
                        },
                        '&:active': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.8)
                        },
                        '&.Mui-disabled': {
                            backgroundColor: 'rgba(29, 27, 32, 0.12)',
                            color: 'rgba(32, 26, 29, 0.38)'
                        }
                    })
                },
                {
                    props: { color: 'primary', variant: 'outlined' },
                    style: ({ theme }) => ({
                        boxShadow: 'none',
                        border: '1px solid #7A757F',
                        background: '#FFFFFF',
                        color: alpha(theme.palette.primary.main, 1),
                        '&:hover': {
                            background: '#FFFFFF',
                            boxShadow: `0 0 0 0.2rem ${alpha(theme.palette.primary.main, 0.3)}`
                        }
                    })
                },
                {
                    props: { color: 'primary', variant: 'text' },
                    style: ({ theme }) => ({
                        boxShadow: 'none',
                        padding: 0,
                        '&:hover': {
                            backgroundColor: 'transparent',
                            textDecoration: 'underline'
                        }
                    })
                },
                {
                    props: { color: 'success', variant: 'contained' },
                    style: ({ theme }) => ({
                        background: alpha(theme.palette.success.main, 1),
                        boxShadow: 'none',
                        '&:hover': {
                            backgroundColor: alpha(theme.palette.success.main, 1),
                            boxShadow: `0 0 0 0.2rem ${alpha(theme.palette.success.main, 0.5)}`
                        },
                        '&:active': {
                            backgroundColor: alpha(theme.palette.success.main, 0.8)
                        },
                        '&.Mui-disabled': {
                            backgroundColor: alpha(theme.palette.success.main, 0.4)
                        }
                    })
                },
                {
                    props: { color: 'error', variant: 'contained' },
                    style: ({ theme }) => ({
                        background: alpha(theme.palette.error.main, 1),
                        boxShadow: 'none',
                        '&:hover': {
                            backgroundColor: alpha(theme.palette.error.main, 1),
                            boxShadow: `0 0 0 0.2rem ${alpha(theme.palette.error.main, 0.5)}`
                        },
                        '&:active': {
                            backgroundColor: alpha(theme.palette.error.main, 0.8)
                        },
                        '&.Mui-disabled': {
                            backgroundColor: alpha(theme.palette.error.main, 0.4)
                        }
                    })
                }
            ]
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    justifyContent: 'space-between',
                    padding: 3,
                    '& .MuiFormControlLabel-root': {
                        marginBottom: '8px'
                    }
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: alpha(theme.palette.primary.main, 1),
                    '&.Mui-checked': {
                        color: alpha(theme.palette.primary.main, 1)
                    },
                    '&:hover': {
                        backgroundColor: alpha(theme.palette.primary.main, 0.04)
                    }
                })
            }
        },
        /*MuiListItem: {
            styleOverrides: {
                root: {
                    '& + .MuiListItem-root': {
                        marginTop: '5px'
                    },
                    borderRadius: '8px',
                    margin: '4px',
                    width: 'unset',
                    '&.Mui-selected': {
                        backgroundColor: '#E3E5C1',
                        '&:hover': {
                            backgroundColor: 'rgba(201,229,193,0.35)'
                        }
                    },
                    '&:hover': {
                        backgroundColor: '#E3E5C159'
                    }
                }
            }
        },*/
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    backgroundColor: 'inherit'
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                fullWidth: {
                    borderBottom: '1px solid rgba(250, 250, 250, 0)',
                    '&:hover': {
                        borderBottom: '1px solid #E3E5C1'
                    }
                }
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    padding: '2px 0 2px',
                    border: 'unset',
                    output: 'unset',
                    '& input:focus-visible': {
                        borderRadius: '8px',
                        outline: 'unset'
                    },
                    '&:before': {
                        borderBottom: 'unset'
                    },
                    '&:focus-visible': {
                        outline: '2px solid #E3E5C1'
                    },
                    '&:after': {
                        borderBottom: '2px solid #E3E5C1'
                    },
                    '&:hover': {
                        borderBottom: 'unset'
                    },
                    '&:hover:not(.Mui-disabled, .Mui-error):before': {
                        borderBottom: 'unset'
                    },
                    '&.Mui-disabled::before': {
                        borderBottomStyle: 'unset'
                    }
                }
            }
        },
        MuiInputAdornment: {
            styleOverrides: {
                positionEnd: {
                    marginRight: 16
                }
            }
        }
    }
})

type IProps = React.PropsWithChildren<{}>

export default function MuiThemeProvider(props: IProps) {
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                {props.children}
            </LocalizationProvider>
        </ThemeProvider>
    )
}
