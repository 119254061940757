import React from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import instance from 'api/axios'
import ModalFormRegistration from './ModalFormRegistration/ModalFormRegistration'
import { Link } from 'react-router-dom'

type TProps = {
    open: boolean
    capturedImage: string | null
    onClose(): void
}

export default function ModalFaceCapturePreview(props: TProps) {
    const [openFormRegistration, setOpenFormRegistration] = React.useState(false)
    const handleOpenFormRegistration = () => {
        setOpenFormRegistration(true)
    }
    const handleCloseFormRegistration = () => {
        setOpenFormRegistration(false)
    }

    const handleClose = () => {
        props.onClose()
    }

    return (
        <Dialog open={props.open} onClose={handleClose} maxWidth="lg">
            <Grid component={Paper} height={'100%'} width={'100%'} padding={5}>
                <DialogTitle>
                    <Box display="flex" alignItems="center" gap={1} marginBottom={3}>
                        <IconButton aria-label="back" component={Link} to="/camera/registration-method">
                            <ArrowBackOutlinedIcon />
                        </IconButton>
                        <Typography variant="h5" flexGrow={1} textAlign="center">
                            Добавление пользователя
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box position="relative" display="inline-block" mb={4}>
                        <img src={props.capturedImage || ''} alt="Captured Face" style={{ maxWidth: '100%', borderRadius: '10px' }} />
                    </Box>
                    <Stack direction="row" justifyContent="center" spacing={2} mt={2}>
                        <Button variant="contained" color="primary" onClick={handleClose}>
                            Переснять
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleOpenFormRegistration}>
                            Продолжить
                        </Button>
                        <ModalFormRegistration capturedImage={props.capturedImage} open={openFormRegistration} onClose={handleCloseFormRegistration} />
                    </Stack>
                </DialogContent>
            </Grid>
        </Dialog>
    )
}
