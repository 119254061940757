import React from 'react'
import { Box, IconButton, Paper, Stack, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import CameraCapture from './CameraCapture/CameraCapture'

export default function CameraFaceRegistration() {
    return (
        <Box minWidth="100vw" minHeight="100vh" bgcolor="rgba(255, 248, 249, 1)" display="flex" alignItems="center" justifyContent="center">
            <Paper elevation={3} sx={{ p: 4, borderRadius: 3, minWidth: '16rem', textAlign: 'center' }}>
                <Stack direction="column" gap={3}>
                    <Box display="flex" alignItems="center" gap={1} marginBottom={3}>
                        <IconButton aria-label="back" component={RouterLink} to="/camera/registration-method">
                            <ArrowBackOutlinedIcon />
                        </IconButton>
                        <Typography variant="h5" flexGrow={1} textAlign="center">
                            Добавление пользователя
                        </Typography>
                    </Box>
                    <Stack direction="column" gap={3}>
                        <CameraCapture />
                    </Stack>
                </Stack>
            </Paper>
        </Box>
    )
}
