import { create } from 'zustand'
import { IChatType } from 'api/ws/ws'
import { IItemMessageOfTextChat } from './chat-messages'
import { CHAT_ROLES } from './group-contacts'

export type IChannelMember = {
    channelId: number
    memberId: number
    isAdmin: boolean
    joined_at: string | null | {}
    confirmInvite: number
}

export type IItemChatChannelOfListContacts = {
    id: number
    type: IChatType.CHANNEL
    title: string
    description: string | null
    ownerUserId: number
    chatRoleId: CHAT_ROLES
    members: IChannelMember[]
    urlAvatar: string | null
    bgColorAvatar: string | null
    createdAt: string | null
    isOnline: boolean // нужен ли этот параметр для каналов и групп?
    isMuted: boolean
    isPined: boolean
    lastMessage: IItemMessageOfTextChat
    lastTimeMessage: string
    lastMessageViewed: boolean
    unreadCount: number
    unreadMessageIds: number[] | null
    timestampEmailSend: Date
}

type IStore = {
    chatChannelsOfListContacts: IItemChatChannelOfListContacts[]
    setChatChannelsOfListContacts(userContacts: IStore['chatChannelsOfListContacts']): void
}

export const useChatChannelsOfListContactsStore = create<IStore>(set => ({
    chatChannelsOfListContacts: [],
    setChatChannelsOfListContacts: data => set(state => ({ chatChannelsOfListContacts: data }))
}))
