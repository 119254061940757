import React from 'react'
import { Box, Typography, Card, CardActionArea, CardContent, Button } from '@mui/material'
import FaceIcon from '@mui/icons-material/Face'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import logo from '../CameraPage/logoSK.svg'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

const CameraRegistrationMethod: React.FC = () => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh" padding={4} sx={{ backgroundColor: '#f5f5f5' }}>
            <img src={logo} alt="Логотип Службы Крови" style={{ marginBottom: '24px', width: '250px' }} />
            <Typography variant="h4" color="textSecondary" textAlign="center" sx={{ fontSize: '28px', marginBottom: '32px', marginTop: '-16px' }}>
                Выберите способ входа
            </Typography>
            <Box display="flex" gap={4} sx={{ marginBottom: '40px' }}>
                <Card sx={{ width: 180, textAlign: 'center', boxShadow: 3, textDecoration: 'none' }} component={Link} to="/camera/signin">
                    <CardActionArea sx={{ height: '100%' }}>
                        {' '}
                        {/* Обеспечиваем полное заполнение */}
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                            {' '}
                            {/* Центрирование содержимого */}
                            <SentimentDissatisfiedIcon sx={{ fontSize: 60, color: '#007B89', marginBottom: 2 }} />
                            <Typography variant="body1" color="#007B89" fontWeight="bold" sx={{ fontSize: '18px' }}>
                                Биометрия отсутствует
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
                <Card sx={{ width: 180, textAlign: 'center', boxShadow: 3, textDecoration: 'none' }} component={Link} to="/camera/face-signin">
                    <CardActionArea sx={{ height: '100%' }}>
                        {' '}
                        {/* Обеспечиваем полное заполнение */}
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                            {' '}
                            {/* Центрирование содержимого */}
                            <FaceIcon sx={{ fontSize: 60, color: '#007B89', marginBottom: 2 }} />
                            <Typography variant="body1" color="#007B89" fontWeight="bold" sx={{ fontSize: '18px' }}>
                                Биометрия есть
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Box>
            <Button
                variant="contained"
                color="primary"
                startIcon={<PersonAddIcon sx={{ fontSize: '24px' }} />}
                component={Link}
                to="/camera/face-registration"
                sx={{
                    backgroundColor: '#007B89',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    padding: '12px 24px',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                        backgroundColor: '#005f6b'
                    },
                    marginBottom: '28px'
                }}
            >
                Регистрация
            </Button>
            <Button
                variant="text"
                color="primary"
                component={Link}
                to="/camera"
                sx={{
                    fontSize: '18px',
                    textTransform: 'none',
                    color: '#007B89'
                }}
            >
                На главную
            </Button>
        </Box>
    )
}

export default CameraRegistrationMethod
