import { useNavigate, useParams } from 'react-router-dom'
import React from 'react'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront'
import LightModeIcon from '@mui/icons-material/LightMode'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import ModeNightIcon from '@mui/icons-material/ModeNight'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ConnectedTvOutlinedIcon from '@mui/icons-material/ConnectedTvOutlined'
import CallIcon from '@mui/icons-material/Call'
import { Avatar, Badge, Box, ButtonBase, Collapse, ListSubheader, Skeleton, Tooltip } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import SettingsIcon from '@mui/icons-material/Settings'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined'
import TodayIcon from '@mui/icons-material/Today'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import OutputIcon from '@mui/icons-material/Output'
import { ROLES, useUserProfileStore } from 'store/user-profile'
import $ListItemButtonLink from 'components/ListItemButtonLink/ListItemButton'
import { useWorkspaceStore } from 'store/workspace'
import $ProtectedComponent from 'components/ProtectedComponent/ProtectedComponent'
import { IItemChatUserOfListContacts, useUserContactsStore } from 'store/user-contacts'
import classnames from 'classnames'
import { getFullUrlImage } from 'utils/helpers'
import { Stack } from '@mui/system'
import { useAuthStore } from 'store/auth'
import { IItemChatChannelOfListContacts, useChatChannelsOfListContactsStore } from '../../store/channel-contacts'
import { IItemChatGroupOfListContacts, useChatGroupsOfListContactsStore } from '../../store/group-contacts'
import { SHOP_EXTENSION, useShopExtensionsStore } from 'store/shop-extensions'
import { IChatType } from '../../api/ws/ws'
import { ReactComponent as SVGAgoraLogo } from './agora-logo.svg'
import { ReactComponent as SVGZegoLogo } from './zego-logo.svg'
import { ReactComponent as SVGLiveKitLogo } from './livekit.svg'
import { ReactComponent as SVGJitSiLogo } from './jitsi.svg'
import styles from './WorkspaceMenu.module.scss'

const cx = classnames.bind(styles)

type IProps = React.PropsWithChildren<{}>

export default function WorkspaceMenu(props: IProps) {
    const navigate = useNavigate()
    const { userId } = useAuthStore()
    const { linkWorkspace } = useParams()
    const { workspace, openMenuWorkspace, setOpenMenuWorkspace } = useWorkspaceStore()
    const { userContacts } = useUserContactsStore()
    const { chatChannelsOfListContacts } = useChatChannelsOfListContactsStore()
    const { chatGroupsOfListContacts } = useChatGroupsOfListContactsStore()
    const { shopExtensions } = useShopExtensionsStore()
    const { userProfile } = useUserProfileStore()
    const [darkMode, setDarkMode] = React.useState(true)
    const [openVideoCalls, setOpenVideoCalls] = React.useState(false)

    const handleDrawerToggle = () => {
        setOpenMenuWorkspace(!openMenuWorkspace)
    }

    const nickname = userProfile?.nickname ? userProfile.nickname : `@${userProfile?.login}`
    const srcAvatar = getFullUrlImage(userProfile?.urlAvatar)
    const isPasswordCompromised = !!userProfile?.isPasswordCompromised ? (
        <Tooltip title="Пароль скомпрометирован">
            <ReportGmailerrorredIcon color="error" fontSize={'small'} />
        </Tooltip>
    ) : undefined

    const isAccessToScreenControl = React.useMemo(() => shopExtensions.find(item => item.id === SHOP_EXTENSION.SCREEN_CONTROL && item.installed), [shopExtensions])
    const isAccessToCalendar = React.useMemo(() => shopExtensions.find(item => item.id === SHOP_EXTENSION.CALENDAR && item.installed), [shopExtensions])
    const isAccessToDisc = React.useMemo(() => shopExtensions.find(item => item.id === SHOP_EXTENSION.DISC && item.installed), [shopExtensions])
    const isAccessToMeetingCallAgora = React.useMemo(() => shopExtensions.find(item => item.id === SHOP_EXTENSION.MEETING_CALL_AGORA && item.installed), [shopExtensions])
    const isAccessToMeetingCallZegoCloud = React.useMemo(() => shopExtensions.find(item => item.id === SHOP_EXTENSION.ZEGOCLOUD && item.installed), [shopExtensions])
    const isAccessToMeetingCallJitSi = React.useMemo(() => shopExtensions.find(item => item.id === SHOP_EXTENSION.JITSI && item.installed), [shopExtensions])
    const isAccessToMeetingCallLiveKit = React.useMemo(() => shopExtensions.find(item => item.id === SHOP_EXTENSION.LIVEKIT && item.installed), [shopExtensions])

    const newMessages = [...userContacts, ...chatGroupsOfListContacts, ...chatChannelsOfListContacts]
        .filter(item => {
            switch (item.type) {
                case IChatType.PERSONAL:
                    return item.emailConfirm && item.login
                case IChatType.GROUP:
                    return (item as IItemChatGroupOfListContacts).members
                        .filter(member => member.confirmInvite && !member.isUserLeft)
                        .map(item => item.memberId)
                        .includes(Number(userId))
                case IChatType.CHANNEL:
                    return (item as IItemChatChannelOfListContacts).members.map(item => item.memberId).includes(Number(userId))
            }
        })
        .filter(item => item.unreadMessageIds?.length && item.unreadMessageIds.length > 0).length

    const urlImage = workspace?.urlImage || ''

    return (
        <div className={styles.WorkspaceMenu} style={{ maxWidth: openMenuWorkspace ? 360 : 80, overflow: 'hidden' }}>
            {workspace?.urlImage ? (
                <div className={styles.Image} style={{ backgroundImage: `url(${urlImage})` }}>
                    <ButtonBase className={cx(styles.ToggleOpen, { [styles.open]: openMenuWorkspace })} onClick={handleDrawerToggle}>
                        {openMenuWorkspace ? <ArrowLeftIcon fontSize="large" /> : <ArrowRightIcon fontSize="large" />}
                    </ButtonBase>
                    {openMenuWorkspace && <h2 className={styles.Title}>{workspace.title}</h2>}
                </div>
            ) : (
                <Skeleton className={styles.Image} variant="rectangular" height="250" width={'100%'} />
            )}
            <div className={styles.Menu} style={{ padding: '0 12px 12px' }}>
                <ListItem disablePadding>
                    <$ListItemButtonLink to={`/${linkWorkspace}/profile-settings`} styles={openMenuWorkspace ? { padding: '8px 16px' } : { padding: 0, margin: '8px 0' }}>
                        <div className={styles.Profile}>
                            <Tooltip title="Настройки профиля" placement="right">
                                <div className={styles.Avatar} style={{ width: '56px', height: '56px' }}>
                                    <Avatar alt={nickname} src={srcAvatar} sx={{ width: '100%', height: '100%' }} />
                                    <Box className={styles.Icon}>
                                        <StarIcon color="inherit" />
                                    </Box>
                                </div>
                            </Tooltip>
                            <div>
                                <Stack direction={'row'} alignItems={'center'} spacing={0.5} justifyContent={'flex-start'}>
                                    <Typography>{nickname}</Typography>
                                    {isPasswordCompromised}
                                </Stack>
                                <Typography variant="caption">{userProfile?.email}</Typography>
                            </div>
                        </div>
                    </$ListItemButtonLink>
                </ListItem>

                <List
                    subheader={
                        <ListSubheader component="div" sx={{ zIndex: 2, visibility: openMenuWorkspace ? 'visibility' : 'hidden' }}>
                            Настройки и справка
                        </ListSubheader>
                    }
                >
                    <ListItem disablePadding>
                        <$ListItemButtonLink to={`/${linkWorkspace}/home`} styles={{ height: '56px', width: openMenuWorkspace && '56px' }}>
                            <Tooltip title="Информация" placement="right">
                                <ListItemIcon>
                                    <InfoOutlinedIcon />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="Информация" />
                        </$ListItemButtonLink>
                    </ListItem>
                    <$ProtectedComponent roles={[ROLES.OWNER, ROLES.ADMIN]}>
                        <ListItem disablePadding>
                            <$ListItemButtonLink to={`/${linkWorkspace}/workspace-settings`} styles={{ height: '56px', width: openMenuWorkspace && '56px' }}>
                                <Tooltip title="Настройки пространства" placement="right">
                                    <ListItemIcon>
                                        <SettingsIcon />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="Настройки пространства" />
                            </$ListItemButtonLink>
                        </ListItem>
                    </$ProtectedComponent>
                </List>
                <Divider />
                <List
                    subheader={
                        <ListSubheader component="div" sx={{ zIndex: 2, visibility: openMenuWorkspace ? 'visibility' : 'hidden' }}>
                            Модули
                        </ListSubheader>
                    }
                >
                    <ListItem disablePadding>
                        <$ListItemButtonLink to={`/${linkWorkspace}/chat-text`} styles={{ height: '56px', width: openMenuWorkspace && '56px' }}>
                            <Tooltip title="Мессенджер" placement="right">
                                <ListItemIcon>
                                    <Badge badgeContent={newMessages} color="primary">
                                        <ChatBubbleOutlineOutlinedIcon />
                                    </Badge>
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="Мессенджер" />
                        </$ListItemButtonLink>
                    </ListItem>
                    {isAccessToCalendar && (
                        <ListItem disablePadding>
                            <$ListItemButtonLink to={`/${linkWorkspace}/chat-calendar`} styles={{ height: '56px', width: openMenuWorkspace && '56px' }}>
                                <Tooltip title="Календарь" placement="right">
                                    <ListItemIcon>
                                        <TodayIcon />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="Календарь" />
                            </$ListItemButtonLink>
                        </ListItem>
                    )}
                    <$ProtectedComponent roles={[ROLES.OWNER, ROLES.ADMIN, ROLES.WATCHER]}>
                        <ListItem disablePadding>
                            <$ListItemButtonLink to={`/${linkWorkspace}/admin-donor-service`} styles={{ height: '56px', width: openMenuWorkspace && '56px' }}>
                                <Tooltip title="Донорский сервис" placement="right">
                                    <ListItemIcon>
                                        <AdminPanelSettingsOutlinedIcon /> {/* Используем иконку для админ панели */}
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="Донорский сервис" />
                            </$ListItemButtonLink>
                        </ListItem>
                    </$ProtectedComponent>
                    {userProfile?.roleId !== ROLES.WATCHER && (
                        <>
                            <ListItem disablePadding onClick={() => setOpenVideoCalls(open => !open)}>
                                <$ListItemButtonLink to={null}>
                                    <Tooltip title="Звонки" placement="right">
                                        <ListItemIcon>
                                            <CallIcon />
                                        </ListItemIcon>
                                    </Tooltip>
                                    <ListItemText primary="Звонки" />
                                    {openVideoCalls ? <ExpandLess /> : <ExpandMore />}
                                </$ListItemButtonLink>
                            </ListItem>
                            <Collapse in={openVideoCalls} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding sx={{ pl: openMenuWorkspace ? 1.5 : undefined }}>
                                    {isAccessToMeetingCallAgora && (
                                        <ListItem disablePadding>
                                            <$ListItemButtonLink to={`/${linkWorkspace}/meeting`} styles={{ height: '56px', width: openMenuWorkspace && '56px' }}>
                                                <Tooltip title="Звонки Agora" placement="right">
                                                    <ListItemIcon>
                                                        <SVGAgoraLogo height={24} />
                                                    </ListItemIcon>
                                                </Tooltip>
                                                <ListItemText primary="Звонки Agora" />
                                            </$ListItemButtonLink>
                                        </ListItem>
                                    )}
                                    {isAccessToMeetingCallZegoCloud && (
                                        <ListItem disablePadding>
                                            <$ListItemButtonLink to={`/${linkWorkspace}/zegocloud`} styles={{ height: '56px', width: openMenuWorkspace && '56px' }}>
                                                <Tooltip title="Звонки ZegoCloud" placement="right">
                                                    <ListItemIcon>
                                                        <SVGZegoLogo height={24} />
                                                    </ListItemIcon>
                                                </Tooltip>
                                                <ListItemText primary="Звонки ZegoCloud" />
                                            </$ListItemButtonLink>
                                        </ListItem>
                                    )}
                                    {isAccessToMeetingCallJitSi && (
                                        <ListItem disablePadding>
                                            <$ListItemButtonLink to={`/${linkWorkspace}/jitsi`} styles={{ height: '56px', width: openMenuWorkspace && '56px' }}>
                                                <Tooltip title="Звонки Jitsi" placement="right">
                                                    <ListItemIcon>
                                                        <SVGJitSiLogo height={24} />
                                                    </ListItemIcon>
                                                </Tooltip>
                                                <ListItemText primary="Звонки Jitsi" />
                                            </$ListItemButtonLink>
                                        </ListItem>
                                    )}
                                    {isAccessToMeetingCallLiveKit && (
                                        <ListItem disablePadding>
                                            <$ListItemButtonLink to={`/${linkWorkspace}/livekit`} styles={{ height: '56px', width: openMenuWorkspace && '56px' }}>
                                                <Tooltip title="Звонки LiveKit" placement="right">
                                                    <ListItemIcon>
                                                        <SVGLiveKitLogo height={24} />
                                                    </ListItemIcon>
                                                </Tooltip>
                                                <ListItemText primary="Звонки LiveKit" />
                                            </$ListItemButtonLink>
                                        </ListItem>
                                    )}
                                </List>
                            </Collapse>
                        </>
                    )}
                    <$ProtectedComponent roles={[ROLES.OWNER, ROLES.ADMIN]}>
                        {isAccessToScreenControl && (
                            <ListItem disablePadding>
                                <$ListItemButtonLink to={`/${linkWorkspace}/control-screens`} styles={{ height: '56px', width: openMenuWorkspace && '56px' }}>
                                    <Tooltip title="Цифровая вывеска" placement="right">
                                        <ListItemIcon>
                                            <ConnectedTvOutlinedIcon />
                                        </ListItemIcon>
                                    </Tooltip>
                                    <ListItemText primary="Цифровая вывеска" />
                                </$ListItemButtonLink>
                            </ListItem>
                        )}
                    </$ProtectedComponent>
                    {isAccessToDisc && (
                        <ListItem disablePadding>
                            <$ListItemButtonLink to={`/${linkWorkspace}/disk`} styles={{ height: '56px', width: openMenuWorkspace && '56px' }}>
                                <Tooltip title="Диск" placement="right">
                                    <ListItemIcon>
                                        <FolderOutlinedIcon />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="Диск" />
                            </$ListItemButtonLink>
                        </ListItem>
                    )}
                </List>
                <Divider />
                <List>
                    <ListItem disablePadding>
                        <$ListItemButtonLink to={`/${linkWorkspace}?exit`} styles={{ height: '56px', width: openMenuWorkspace && '56px' }}>
                            <Tooltip title="Выход" placement="right">
                                <ListItemIcon>
                                    <OutputIcon />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText
                                onClick={() => {
                                    if (process.env.REACT_APP_SESSION_STORAGE === 'true') {
                                        sessionStorage.clear()
                                    } else {
                                        localStorage.clear()
                                    }
                                }}
                                primary="Выход"
                            />
                        </$ListItemButtonLink>
                    </ListItem>
                </List>
            </div>
        </div>
    )
}
