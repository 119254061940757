import axios from "axios";
import { SnackbarProvider, useSnackbar } from 'notistack';
import { refreshAccessTokenFn } from "./auth/auth";

const BASE_URL = `${process.env.REACT_APP_BACKEND}`;

const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: false,
});

instance.defaults.headers.common['Content-Type'] = 'application/json';

// instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;
  
instance.interceptors.request.use(async (request) => {
    const token = process.env.REACT_APP_SESSION_STORAGE === "true" ? sessionStorage.getItem('token') : localStorage.getItem('token');
    request.headers.Authorization =  token ? `Bearer ${token}` : '';
    return request;
});


instance.interceptors.response.use(
    (response) => {
        if (response.status === 200) {
           
        }
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        const errMessage = error.response.data.message as string;
        if (errMessage.includes('not logged in') && !originalRequest._retry) {
            originalRequest._retry = true;
            await refreshAccessTokenFn();
            return instance(originalRequest);
        }
        return Promise.reject(error);
    }
);

export default instance